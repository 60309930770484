<template>
	<!-- 项目节点设置 -->
	<div class="mod-config">
		<el-card body-style="padding-bottom: 0;" style="margin-top: -5px;">
			<div class="title"> 工程节点进度设置 </div>
			<div class="line"></div>
			<el-row style="margin-top: -8px;margin-left: -20px;line-height:30px;width: 103%;">
				<el-row style="margin-top: 10px;">
					<el-col :span="2">
						<div class="search_title">标的物</div>
					</el-col>
					<el-col :span="22" style="display: flex;flex-wrap: wrap;">
						<span v-for="(subject, index) in subjectList" style="margin-right: 20px;">
							<span class="search_bn_border" v-if="dataForm.subjectNo === subject.displayValue"
								@click="setSubject(subject)">
								{{ subject.displayName }}
							</span>
							<span class="search_bn" v-else-if="dataForm.subjectNo !== subject.displayValue"
								@click="setSubject(subject)">
								{{ subject.displayName }}
							</span>
						</span>
					</el-col>
				</el-row>
				<div class="line" style="margin-top: 10px;"></div>
				<el-row style="background-color: rgb(243, 247, 247);margin-top: -10px;height: 45px;width: 103%;">
					<el-col :span="pageSpan" style="margin-left:15px;margin-top:5px">
						<el-button v-preventReClick class="btn" type="primary" size="small" v-if="showAdd"
							@click="addOrUpdateHandle()">新增</el-button>
					</el-col>
				</el-row>
				<div class="line" style="margin-top: -4px;"></div>
				<el-form ref="form" :model="dataForm" inline label-width="80px" label-position="left">
					<el-row
						style="background-color: rgb(243, 247, 247);margin-top: -10px;margin-left: -20px;height: 45px;width: 103%;">
						<!-- <el-col :span="19" style="margin-left:20px">
							<el-form-item label="标的物" class="selFormItem">
								<el-select class="selSubjectInput" v-model="dataForm.subjectNo" placeholder="请选择">
									<el-option v-for="item in $store.state.common.navBarSubjectList"
										:key="item.displayValue" :label="item.displayName" :value="item.displayValue">
									</el-option>
								</el-select>
							</el-form-item>
						</el-col> -->
						<el-col :span="23" class="selBtnGroup" style="margin-top:5px">
							<el-button v-preventReClick class="btn" type="primary" size="small" @click="getDataList()"
								v-if="showSearch">查询</el-button>
							<el-button v-preventReClick class="rebtn" size="small" @click="resetFormData()">重置</el-button>
						</el-col>
					</el-row>
				</el-form>
			</el-row>
		</el-card>
		<div>
			<el-card style="margin-top: -5px; height: 500px;" body-style="height:460px; padding: 0;">
				<el-row style="height: 100%; margin-bottom: 10px;">
					<el-col :span="24" style="height: 100%;">
						<div class="box">
							<el-table height="440" :row-class-name="tableRowClassName" class="dataListTable" border
								:data="dataList" header-align="center" style="width: 100%; margin-bottom: 20px;"
								:cell-style="{ padding: '0px' }" header-cell-class-name="stepPointTHeader">
								<el-table-column width="30" type="expand" prop="records">
									<template slot-scope="props">
										<el-table :data="props.row.records" size="small" border header-align="center">
											<el-table-column align="center" type="index" width="50">
											</el-table-column>
											<el-table-column align="center" label="变更时间" width="200" prop="changeTime">
											</el-table-column>
											<el-table-column align="left" label="变更原因" prop="changeRsn"
												header-align="center">
											</el-table-column>
											<el-table-column align="center" label="操作"
												v-if="showChangePlanDelete">
												<template slot-scope="scope">
													<el-button v-preventReClick type="danger" size="small"
														@click="deleteHandle(scope.row.recNo, scope.row.changeTime)">删除</el-button>
													<!--										<el-button v-preventReClick v-if="isAuth('business:nodeinfo:delete')" type="danger" size="small" @click="deleteHandle" >批量删除</el-button>-->
												</template>
											</el-table-column>
										</el-table>
									</template>
								</el-table-column>
								<!-- <el-table-column prop="subjectName" label="标的物" width="100" align="center">
								</el-table-column> -->
								<el-table-column prop="nodeName" label="节点" width="120" align="center">
								</el-table-column>
								<el-table-column prop="planTime" label="计划日期" width="110" align="center">
								</el-table-column>
								<el-table-column prop="actualTime" label="实际日期" width="110" align="center">
								</el-table-column>
								<el-table-column prop="changeTimes" label="调整次数" width="110" align="center">
								</el-table-column>
								<el-table-column prop="remark" label="描述" align="left" header-align="center">
								</el-table-column>
								<el-table-column fixed="right" header-align="center" align="center" width="350" label="操作"
									v-if="showChangePlan || showConfirm || showEdit || showDelete">
									<template slot-scope="scope">
										<el-button v-preventReClick type="text" size="mini"
											@click="adjustmentHandle(scope.row.nodeId)"
											v-if="showChangePlan">调整计划 </el-button>
										<el-button v-preventReClick type="text" size="mini"
											@click="confirmHandle(scope.row.nodeId)"
											v-if="showConfirm">确认</el-button>
										<el-button v-preventReClick type="text" size="mini"
											@click="addOrUpdateHandle(scope.row.nodeId)"
											v-if="showEdit">修改</el-button>
										<el-button v-preventReClick type="text" size="mini"
											@click="deleteNodeHandle(scope.row.nodeId)"
											v-if="showDelete">删除</el-button>
									</template>
								</el-table-column>
							</el-table>
						</div>
						<el-pagination class="my-pagination" @size-change="sizeChangeHandle"
							@current-change="currentChangeHandle" :current-page="pageIndex" :page-sizes="[10, 20, 50, 100]"
							:page-size="pageSize" :total="totalPage" layout="total, sizes, prev, pager, next, jumper">
						</el-pagination>
					</el-col>
				</el-row>
			</el-card>
		</div>
		<!-- 弹窗, 新增节点 -->
		<add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
		<adjustment-handle v-if="adjustmentVisible" ref="adjustment" @refreshDataList="getDataList"></adjustment-handle>
		<confirm-handle v-if="confirmVisible" ref="confirm" @refreshDataList="getDataList"></confirm-handle>
	</div>
</template>
<script>
import AddOrUpdate from './nodeList-add-or-update'
import AdjustmentHandle from './nodeList-adjustment'
import ConfirmHandle from './nodeList-confirm'
import $common from "@/utils/common.js"
export default {
	data() {
		return {
			pageSpan: 12,
			showSearch: false,
			showAdd: false,
			showChangePlan: false,
			showChangePlanDelete: false,
			showConfirm: false,
			showEdit: false,
			showDelete: false,
			addOrUpdateVisible: false,
			adjustmentVisible: false,
			confirmVisible: false,
			dataList: [],
			subjectList: [],
			dataListSelections: [],
			dataForm: {
				workNo: "",
				subjectNo: ""
			},
			//当前页码
			pageIndex: 1,
			//当前记录数
			pageSize: 20,
			//总页数
			totalPage: 0,
		};
	},
	components: {
		AddOrUpdate,
		AdjustmentHandle,
		ConfirmHandle
	},
	mounted() {
		this.showSearch = $common.isAuth('nodeList:search');
		this.showAdd = $common.isAuth('nodeList:add');
		this.showChangePlan = $common.isAuth('nodeList:change:plan');
		this.showChangePlanDelete = $common.isAuth('nodeList:change:plan:delete');
		this.showConfirm = $common.isAuth('nodeList:confirm');
		this.showEdit = $common.isAuth('nodeList:edit');
		this.showDelete = $common.isAuth('nodeList:delete');
		if (!this.showAdd) {
			this.pageSpan = 24;
		}
		// if (this.showSearch) {
		// 	this.getDataList();
		// }
		this.getSubjectDropDown();
	},
	methods: {
		resetFormData() {
			// this.dataForm.subjectNo = '';
			this.pageIndex = 1;
			this.getDataList();
		},
		getSubjectDropDown() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getSubjectDropDown/" + $common.getItem("workNo"),
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.subjectList = data.body;
					if (this.subjectList != undefined && this.subjectList.length > 0) {
						this.dataForm.subjectNo = this.subjectList[0].displayValue
						if (this.showSearch) {
							this.getDataList();
						}
					}
				}
			});
		},
		setSubject(subject) {
			this.dataForm.subjectNo = subject.displayValue
			this.getDataList()
		},
		// 获取数据列表
		getDataList() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/nodeinfo/listAndNodeChange",
				method: 'get',
				params: this.$http.adornParams({
					page: this.pageIndex,
					limit: this.pageSize,
					workNo: $common.getItem("workNo"),
					subjectNo: this.dataForm.subjectNo,
				})
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.dataList = data.body.records;
					this.totalPage = data.body.totalCount;
				} else {
					this.dataList = []
					this.totalPage = 0
				}
			})
		},
		selectionChangeHandle(val) {
			this.dataListSelections = val
		},
		//自适应高度
		siteContentViewHeight() {
			var height = this.$store.state.common.documentClientHeight - 260;
			return {
				height: height + 'px'
			}
		},
		// 每页数
		sizeChangeHandle(val) {
			this.pageSize = val
			this.pageIndex = 1
			this.getDataList()
		},
		// 当前页
		currentChangeHandle(val) {
			this.pageIndex = val
			this.getDataList()
		},
		//选中背景变色
		getRowClass(row, rowIndex) {
			if (!row.row.records || row.row.records.length === 0) {
				return 'row-expand-cover'
			}
			return ''
		},
		// 新增节点
		addOrUpdateHandle(id) {
			this.addOrUpdateVisible = true
			this.$nextTick(() => {
				this.$refs.addOrUpdate.init(id, this.dataForm.subjectNo)
			})
		},
		// 调整修改节点
		adjustmentHandle(id) {
			this.adjustmentVisible = true
			this.$nextTick(() => {
				this.$refs.adjustment.init(id)
			})
		},
		// 确认调整修改
		confirmHandle(id) {
			this.confirmVisible = true
			this.$nextTick(() => {
				this.$refs.confirm.init(id)
			})
		},
		deleteNodeHandle(id) {
			this.$confirm(`您确定对选中项目节点记录进行删除操作?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http({
					url: this.$store.state.httpUrl + "/business/nodeinfo/delete",
					method: 'post',
					data: this.$http.adornData([id], false)
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getDataList()
							}
						})
					} else {
						this.$message.error(data.msg)
					}
				})
			})
		},
		// 删除
		deleteHandle(id, changeTime) {
			var ids = id ? [id] : this.dataListSelections.map(item => {
				return item.recNo
			});
			// let ids = [id];
			this.$confirm(`您确定对选中记录进行删除操作?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$http({
					url: this.$store.state.httpUrl + "/business/nodeinfo/deleteNodeChange",
					method: 'post',
					// data: ids,
					data: this.$http.adornData(ids, false)
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getDataList()
							}
						})
					} else {
						this.$message.error(data.msg)
					}
				})
			})
		},
		tableRowClassName({ row, rowIndex }) {
			return $common.tableRowClassName({ row, rowIndex });
		},
	}
};
</script>
<style lang="scss" scoped>
.my-pagination {
	margin-right: 20px !important;
}

::v-deep(.el-table th.el-table__cell) {
	// background-color: #d3e2f4 !important;
	font-size: 15px;
	padding: 2px 0;
}

::v-deep(.selSubjectInput .el-input__inner) {
	width: 120px !important;
	height: 30px !important;
}

::v-deep(.selFormItem .el-form-item__label) {
	font-size: smaller;
}
</style>
