<template>
	<el-dialog :title="!dataForm.nodeId ? '新增节点' : '修改节点'" :close-on-click-modal="false" :visible.sync="visible"
		width="530px">
		<el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="140px" :inline="true" class="border-form">
			<el-row class="set_border">
				<el-col :span="24" class="col-set_border">
					<el-form-item label="标的物" prop="subjectNo" class="item">
						<el-select class="selItemInput1" v-model="dataForm.subjectNo" placeholder="请选择">
							<el-option v-for="item in subjectList" :key="item.displayValue" :label="item.displayName"
								:value="item.displayValue">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="set_border">
				<el-col :span="24" class="col-set_border">
					<el-form-item label="节点名称" prop="nodeName" class="item">
						<el-input class="selItemInput1" v-model="dataForm.nodeName" placeholder="节点名称"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="set_border">
				<el-col :span="24" class="col-set_border">
					<el-form-item label="计划时间" prop="planTime" class="item">
						<el-date-picker class="selItemInput1" value-format="yyyy-MM-dd" v-model="dataForm.planTime"
							type="date">
						</el-date-picker>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="set_border">
				<el-col :span="24" class="col-set_border">
					<el-form-item label="节点描述" class="item">
						<el-input class="selItemInput2" v-model="dataForm.remark" type="textarea" placeholder="描述一下节点内容"
							rows="4"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
		<el-row class="formBtnGroup">
			<el-button v-preventReClick class="btn" type="primary" size="small" @click="dataFormSubmit()">确定</el-button>
			<el-button v-preventReClick @click="visible = false" class="btn" type="primary" size="small">取消</el-button>
		</el-row>
	</el-dialog>
</template>

<script>
import $common from "@/utils/common.js"
export default {
	name: "nodeList-add-or-update",
	data() {
		return {
			//当前页码
			pageIndex: 1,
			//当前记录数
			pageSize: 5,
			// nodeinfoList : [],
			visible: false,
			inline: false,
			activeNames: ['1'],
			plannedTime: '',
			description: '',
			dataForm: {
				//编号(调用保存的方法)
				nodeId: '',
				//节点名称
				nodeName: '',
				//计划时间
				planTime: '',
				//备注
				remark: '',
				subjectNo: '',
			},
			subjectList: [],
			dataRule: {
				subjectNo: [
					{ required: true, message: "标的物不能为空", trigger: "blur" }
				],
				remark: [
					{ required: true, message: "备注", trigger: "blur" }
				],
				nodeName: [
					{ required: true, message: "节点名称不能为空", trigger: "blur" }
				],
				planTime: [
					{ required: true, message: "计划时间不能为空", trigger: "blur" }
				]
			},
		}
	},
	methods: {
		init(id, subjectNo) {
			this.visible = true;
			this.dataForm = {
				nodeId: '',
				//节点名称
				nodeName: '',
				//计划时间
				planTime: '',
				//备注
				remark: '',
				subjectNo: subjectNo,
			},
				this.getSubjectDropDown(),
				this.$nextTick(() => {
					this.dataForm.nodeId = id ? id : 0;
					if (this.dataForm.nodeId) {
						this.$http({
							url: this.$store.state.httpUrl + `/business/nodeinfo/info/${this.dataForm.nodeId}`,
							method: 'get',
							params: this.$http.adornParams()
						}).then(({ data }) => {
							if (data && data.resultCode === 200) {
								this.dataForm.subjectNo = data.body.subjectNo
								this.dataForm.nodeName = data.body.nodeName
								this.dataForm.planTime = data.body.planTime
								this.dataForm.remark = data.body.remark
							}
						})
					}
				})
		},
		getSubjectDropDown() {
			this.$http({
				url: this.$store.state.httpUrl + "/common/getSubjectDropDown/" + $common.getItem("workNo"),
				method: "get",
				params: {},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.subjectList = data.body;
				}
			});
		},
		// 表单提交
		dataFormSubmit() {
			this.$refs["dataForm"].validate(valid => {
				if (valid) {
					this.$http({
						url: this.$store.state.httpUrl +
							`/business/nodeinfo/${!this.dataForm.nodeId ?
								"save" : "update"}`,
						method: "post",
						data: this.$http.adornData({
							workNo: $common.getItem("workNo"),
							subjectNo: this.dataForm.subjectNo,
							nodeId: this.dataForm.nodeId,
							nodeName: this.dataForm.nodeName,
							planTime: this.dataForm.planTime,
							remark: this.dataForm.remark
						})
					}).then(({ data }) => {
						if (data && data.resultCode === 200) {
							this.$message({
								message: "操作成功",
								type: "success",
								duration: 1500,
								onClose: () => {
									this.visible = false;
									this.$parent.getDataList();
								}
							});
						} else {
							this.$message.error(data.msg);
						}
					});
				}
			});
		},
	}
}
</script>
<style scoped>
.selItemInput2 {
	width: 320px !important;
	/* margin-left: 10px !important; */
	/* margin-left: -02px; */
}

.selItemInput1 {
	width: 320px !important;
	/* margin-left: -02px; */
}

.item::v-deep .el-form-item__label {
	color: #00428E !important;
	margin-right: 10px !important;
	font-weight: bold !important;
	background-color: #E1F1FF;
	white-space: nowrap !important;
	border-right: solid #dadada 1px;
}

::v-deep .el-form-item {
	display: flex !important;
	align-items: stretch !important;
	margin-bottom: 0px !important;
}

.border-form .el-row.set_border {
	border-bottom: solid #dadada 1px;
	border-left: solid #dadada 1px;
	border-right: solid #dadada 1px;
}

.border-form .el-row.set_border:first-child {
	border-top: solid #dadada 1px;
}

.border-form .el-row.set_border .col-set_border {
	border-right: solid #dadada 1px;
}
</style>
