<template>
	<!-- 调整计划节点 -->
	<el-dialog title="调整计划" :close-on-click-modal="false" :visible.sync="visible" width="500px" class="border-form">
		<el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="120px" :inline="true">
			<el-row class="set_border">
				<el-col :span="24" class="col-set_border">
					<el-form-item label="变更时间" prop="changeTime" class="item">
						<el-date-picker class="selItemInput" value-format="yyyy-MM-dd" v-model="dataForm.changeTime"
							type="date" style="width:300px">
						</el-date-picker>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="set_border">
				<el-col :span="24" class="col-set_border">
					<el-form-item label="变更原因" prop="changeRsn" class="item">
						<el-input class="selItemInput" type="textarea" :rows="3" v-model="dataForm.changeRsn"
							placeholder="变更原因" style="width:300px"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
		<el-row class="formBtnGroup">
			<el-button v-preventReClick class="btn" type="primary" size="small" @click="dataFormSubmit()">保存</el-button>
			<el-button v-preventReClick @click="visible = false" class="rebtn" size="small">取消</el-button>
		</el-row>
	</el-dialog>
</template>

<script>
import $common from "@/utils/common.js"
export default {
	name: "nodeList-adjustment",
	data() {
		return {
			//当前页码
			pageIndex: 1,
			//当前记录数
			pageSize: 5,
			nodeinfoList: [],
			visible: false,
			inline: false,
			activeNames: ['1'],
			dataForm: {
				//编号(调用保存的方法时)
				recNo: "",
				//备注
				remark: '',
				//编号(调用保存的方法)
				nodeId: '',
				//变更时间
				changeTime: '',
				//变更原因
				changeRsn: '',
			},
			dataRule: {
				changeTime: [
					{ required: true, message: "请选择变更时间", trigger: "change" }
				],
				changeRsn: [
					{ required: true, message: "请输入变更原因", trigger: "blur" }
				]
			},
		}
	},
	methods: {
		init(id) {
			this.dataForm = {
				//编号(调用保存的方法时)
				recNo: "",
				//备注
				remark: '',
				//编号(调用保存的方法)
				nodeId: '',
				//变更时间
				changeTime: '',
				//变更原因
				changeRsn: '',
			}
			this.dataForm.nodeId = id || 0;
			this.visible = true;
			this.$nextTick(() => {
				this.$refs['dataForm'].resetFields()
				if (this.dataForm.nodeId) {
					this.$http({
						url: this.$store.state.httpUrl + `/business/nodeinfo/info/${this.dataForm.nodeId}`,
						method: 'get',
						params: this.$http.adornParams()
					}).then(({ data }) => {
						if (data && data.resultCode === 200) {
							this.dataForm.nodeId = data.body.nodeId
							// this.dataForm.subjectNo = data.body.subjectNo
							//  this.dataForm.nodeName = data.body.nodeName
							//  this.dataForm.planTime = data.body.planTime
							this.dataForm.remark = data.body.remark
						}
					})
				}
			})
		},
		// 表单提交
		dataFormSubmit() {
			// console.log("表单提交")
			this.$refs["dataForm"].validate(valid => {
				if (valid) {
					this.$http({
						url: this.$store.state.httpUrl + `/business/nodeinfo/change/`,
						method: "post",
						data: this.$http.adornData({
							workNo: $common.getItem("workNo"),
							nodeId: this.dataForm.nodeId,
							recNo: this.dataForm.recNo,
							changeRsn: this.dataForm.changeRsn,
							changeTime: this.dataForm.changeTime,
							remark: this.dataForm.remark
						})
					}).then(({ data }) => {
						if (data && data.resultCode === 200) {
							this.$message({
								message: "操作成功",
								type: "success",
								duration: 1500,
								onClose: () => {
									this.visible = false;
									this.$parent.getDataList();
								}
							});
						} else {
							this.$message.error(data.msg);
						}
					});
				}
			});
		},
	}
}
</script>
<style lang="scss" scoped>
.item::v-deep .el-form-item__label {
	color: #00428E !important;
	margin-right: 10px !important;
	font-weight: bold !important;
	background-color: #E1F1FF;
	white-space: nowrap !important;
	border-right: solid #dadada 1px;
}

::v-deep .el-form-item {
	display: flex !important;
	align-items: stretch !important;
	margin-bottom: 0px !important;
}

.border-form .el-row.set_border {
	border-bottom: solid #dadada 1px;
	border-left: solid #dadada 1px;
	border-right: solid #dadada 1px;
}

.border-form .el-row.set_border:first-child {
	border-top: solid #dadada 1px;
}

.border-form .el-row.set_border .col-set_border {
	border-right: solid #dadada 1px;
}
</style>
